import { delay } from "lodash";

export function run(){
    window.onbeforeunload = function (e) {
        if(_start){
            var e = e || window.event;
    
            if (e) {
                e.returnValue = 'Változtatások elvesznek az lap elhagyásával! Biztos elhagyod?';
            }
            else{
                return 'Változtatások elvesznek az lap elhagyásával! Biztos elhagyod?';
            }
        }
    }
    let _timer = false;
    let _time = 30;
    let _start = false;
    let _intro_url = '';
    let _check_url = '';
    let _start_url = '';
    let _self = false;
    let questions = [];
    let step = 1;
    let _point = 0;
    return {
        init(question_list, intro_url, start_url, check_url){
            questions = question_list;
            _check_url = check_url;
            _intro_url = intro_url;
            _start_url = start_url;
            _start = true;
            _self = this;
            this.nextQuestion();
        },

        timer(state){
            if(state == 'start'){
                _time = 30;
                this.setTime();
                _timer = setInterval(function(){
                    _time--;
                    if(_time <= 0){
                        clearInterval(_timer);
                        _time = 0;
                        swal({
                            title: 'Lejárt az időd :(!',
                            type: 'error',
                            timer: 6000,
                            showConfirmButton: false
                        }).then(function(){
                            _start = false;
                            window.location.href = _intro_url;
                        });
                    }
                    _self.setTime();
                }, 1000);
            }else{
                clearInterval(_timer);
            }
        },

        setTime(){
            $('.game-2-page .time-text span').html(_time);
            $('.game-2-page .game-status-bar span').css('width', `${100-((_time/30) * 100)}%`);
        },

        check(){
            if($('.game-2-page .answers .answer input:checked').length){
                this.timer('stop');
                var answers = [];
                $('.game-2-page .answers .answer input:checked').each(function(){
                    answers.push($(this).val());
                });

                $('.game-2-page .btn-check').hide();

                axios.put(_check_url, {question: questions[step - 1].id, answers: answers})
                .then(function (response) {
                    if(response.data.answers != undefined){
                        $(`.answers .answer input`).attr('disabled', true);
                        for(var i in response.data.answers.good){
                            $(`#answer-${response.data.answers.good[i]}`).parent('div').addClass('good');
                        }
                        for(var i in response.data.answers.wrong){
                            $(`#answer-${response.data.answers.wrong[i]}`).parent('div').addClass('wrong');
                        }
                        for(var i in response.data.answers.ok){
                            $(`#answer-${response.data.answers.ok[i]}`).parent('div:not(.wrong)').removeClass('good').addClass('ok');
                        }
                    }
                    
                    if($('.game-2-page .answers .image-block').hasClass('dog')){
                        $('.game-2-page .answers .image-block').html(`<img src="/assets/web/images/${response.data.good == 1 ? 'kutya_elegedett' : 'kutya_szomoru'}.png" class="img-fluid question-image">`);
                    }

                    if(response.data.point != undefined){
                        _point += response.data.point;
                        $(`.game-2-page .footer .point`).html(_point);
                    }

                    if(step < questions.length){
                        step++;
                        $('.game-2-page .btn-next').removeClass('d-none').addClass('d-block');
                    }else{
                        _start = false;
                        $('.game-2-page .btn-finished').removeClass('d-none').addClass('d-block');
                    }

                })
                .catch(function (error) {
                    swal({
                        title: 'Valami nem kerek, előröl kell kezdeni a párbajt',
                        type: 'error',
                        timer: 4000,
                        showConfirmButton: false
                    }).then(function(){
                        _start = false;
                        window.location.href = _intro_url;
                    });
                });
            }else{
                helper.alert('error', 'Legalább 1 válasz megadása kötelező!');
            }
        },

        nextQuestion(){
            $('html, body').scrollTop(0);
            helper.hideQuestion('.game-2-page');
            $('.game-2-page .btn-next').removeClass('d-block').addClass('d-none');
            let item = questions[step - 1];
            $('.game-2-page .status-text span').html(`${step}/${questions.length}`);

            if(item.image != null){
                $('.game-2-page .answers .image-block').html(`<img src="/storage/${item.image}" class="img-fluid question-image" style="display:none;">`).removeClass("dog").addClass('custom-image');
            }else{
                $('.game-2-page .answers .image-block').html(`<img src="/assets/web/images/kutya_tanacstalan.png" class="img-fluid question-image" style="display:none;">`).removeClass('custom-image').addClass('dog');
            }

            
            if(item.description != '' || item.description != null){
                $('.game-2-page .question-text').html(item.description);
            }
            if(item.question != ''){
                $('.game-2-page .question').html(item.question + '<i></i>');
            }
            if(item.help != '' || item.help != null){
                $('.game-2-page .help-text').html(item.help);
            }

            $('.game-2-page .answers .answers-block').html('');
            var answer_template = $("#answer-template").html().trim();
            for(var i in item.answers){
                var answer = $(answer_template).clone(true);
                if((item.help != '' || item.help != null) && item.answers[i].type == 'checkbox'){
                    $('.game-2-page .question i').html(' (Több válasz is lehetséges)');
                }
                if(item.answers[i].image != null){
                    answer.find('label').attr('for', 'answer-' + item.answers[i].id).html(`<img src="/storage/${item.answers[i].image}" class="img-fluid">${item.answers[i].answer}`);
                }else{
                    answer.find('label').attr('for', 'answer-' + item.answers[i].id).html(item.answers[i].answer);
                }
                    
                answer.find('input').attr('type', item.answers[i].type)
                                    .attr('id', 'answer-' + item.answers[i].id)
                                    .attr('name', item.answers[i].type == 'radio' ? 'answer' : 'answer-' + item.answers[i].id)
                                    .val(item.answers[i].id);
                
                answer.appendTo('.game-2-page .answers .answers-block');
            }

            var delay = helper.visibleQuestion('.game-2-page');
            
            axios.put(_start_url, {question: questions[step - 1].id},{
                delay: delay - 1000
            })
            .then(function () {
                _self.timer('start'); 
            })
            .catch(function (error) {
                swal({
                    title: 'Valami nem kerek, előröl kell kezdeni a párbajt',
                    type: 'error',
                    timer: 4000,
                    showConfirmButton: false
                }).then(function(){
                    _start = false;
                    window.location.href = _intro_url;
                });
            });
        },

        jquery(){}
    }
}
