import { delay } from "lodash";

export function run(){
    window.onbeforeunload = function (e) {
        if(start){
            var e = e || window.event;
    
            if (e) {
                e.returnValue = 'Változtatások elvesznek az lap elhagyásával! Biztos elhagyod?';
            }
            else{
                return 'Változtatások elvesznek az lap elhagyásával! Biztos elhagyod?';
            }
        }
    }
    let start = false;
    let game_url = '';
    let game_save_url = '';
    let questions = [];
    let attitude_profiles = [];
    let attitude_profile = {
        id: 0,
        point: 0
    };
    let step = 1;
    return {
        init(question_list, attitude_profile_list, new_url, save_url){
            game_url = new_url;
            game_save_url = save_url;
            questions = question_list;
            attitude_profiles = attitude_profile_list;
            this.nextQuestion();
        },

        reset(){
            start = false;
            window.location.href = game_url;
        },

        check(){
            if($('.game-3-page .answers .answer input:checked').length){
                $('.game-3-page .answers .answer input:checked').each(function(){
                    if(questions[step - 1].attitude_answers[$(this).val() - 1] != undefined){
                        attitude_profiles['attitude_profile_' + questions[step - 1].attitude_answers[$(this).val() - 1].attitude_profiles_id].point += parseInt(questions[step - 1].attitude_answers[$(this).val() - 1].point);
                    }else{
                        helper.alert('error', 'Hiba történt!');
                    }
                });
                
                if(step < questions.length){
                    start = true;
                    step++;
                    this.nextQuestion();
                }else{
                    for(var i in attitude_profiles){
                        if(attitude_profiles[i].point > attitude_profile.point){
                            attitude_profile = attitude_profiles[i];
                        }
                    }
                    $('#attitude-profile-summary-modal .attitude-name').html(attitude_profile.name);
                    $('#attitude-profile-summary-modal .attitude-description').html(attitude_profile.description);
                    $('#attitude-profile-summary-modal').modal('show');
                }
            }else{
                helper.alert('error', 'Legalább 1 válasz megadása kötelező!');
            }
        },

        finish(){
            if(attitude_profile.id != 0){
                start = false;
                axios.put(game_save_url, {attitude_profile_id: attitude_profile.id})
                .then(function (response) {
                    if(response.data.status == 'success'){
                        swal({
                            title: 'Sikeresen mentettük',
                            type: 'success',
                            timer: 2000,
                            showConfirmButton: false
                        }).then(function(){
                            if(response.data.url != undefined){
                                window.location.href = response.data.url;
                            }
                        });
                    }else{
                        swal({
                            title: 'Köszönjük a játékot',
                            type: 'success',
                            timer: 2000,
                            showConfirmButton: false
                        }).then(function(){
                            if(response.data.url != undefined){
                                window.location.href = response.data.url;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    swal({
                        title: 'Sikertelen mentés',
                        type: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                });
            }
        },

        nextQuestion(){
            $('main').scrollTop(0);
            helper.hideQuestion('.game-3-page');
            let item = questions[step - 1];
            $('.game-3-page .status-text span').html(`${step}/${questions.length}`);
            $('.game-3-page .game-status-bar span').css('width', `${(step/questions.length) * 100}%`);
            
            
            if(item.image != null){
                $('.game-3-page .answers .image-block').html(`<img src="/storage/${item.image}" class="question-image img-fluid" style="display:none;">`);
            }else{
                $('.game-3-page .answers .image-block').html(`<img src="/assets/web/images/life_stages/${item.life_stege_image}" class="question-image img-fluid" style="display:none;">`);
            }            

            $('.game-3-page .answers .answer input').removeAttr('checked');
            $('.game-3-page .answers .answer input').prop('checked', false);
            if(item.description != ''){
                $('.game-3-page .question-text').html(item.description);
            }
            if(item.question != ''){
                $('.game-3-page .question').html(item.question);
            }
            if(item.help != ''){
                $('.game-3-page .help-text').html(item.help);
            }

            for(var i in item.attitude_answers){
                var img = '';
                if(item.attitude_answers[i].image != null){
                    img = `<img src="/storage/${item.attitude_answers[i].image}" class="img-fluid">`;
                }
                $('.game-3-page .answers .answer').eq(i).find('label').html(img + item.attitude_answers[i].answer);
            }

            helper.visibleQuestion('.game-3-page');
        },

        jquery(){}
    }
}
