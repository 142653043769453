export function run(){
    window.onbeforeunload = function (e) {
        if(_start){
            var e = e || window.event;
    
            if (e) {
                e.returnValue = 'Változtatások elvesznek az lap elhagyásával! Biztos elhagyod?';
            }
            else{
                return 'Változtatások elvesznek az lap elhagyásával! Biztos elhagyod?';
            }
        }
    }
    var _insurance_max_answer;
    var _insurance_check_url;
    var _attitude_check_url;
    var _education_check_url;
    var _finished_url;
    var _attitude_profile;
    var _level;
    let _start = false;
    let _point = 0;
    let _self = false;

    return {
        init(level, insurance_max_answer, point, insurance_check_url, attitude_check_url, education_check_url){
            _level = level;
            _insurance_max_answer = insurance_max_answer;
            _point = point;
            _insurance_check_url = insurance_check_url;
            _attitude_check_url = attitude_check_url;
            _education_check_url = education_check_url;
            _self = this;
            helper.hideQuestion('.game-1-page #question-type-2');
            helper.visibleQuestion('.game-1-page #question-type-2');
            if(_level == 1){
                $('#life-stage-start-modal').modal('show');
            }
        },
        nextQuestion(type){
            $('html, body').scrollTop(0);
            $('#question-type-2, #wheel, #question-type-3, #question-type-1').addClass('d-none');
            if(type == 'wheel'){
                $(`#wheel`).removeClass('d-none');
                _self.wheelOpt();
            }else if(type == 'attitude'){
                helper.hideQuestion('.game-1-page #question-type-3');
                $(`#question-type-3`).removeClass('d-none');
                $(`#wheel-modal .btn-go-attitude`).addClass('d-none');
                $(`#wheel-modal .modal-dialog.first`).removeClass('first');
                $(`#wheel-modal`).modal('hide');
                helper.visibleQuestion('.game-1-page #question-type-3');
            }else if(type == 'educational'){
                helper.hideQuestion('.game-1-page #question-type-1');
                $(`#question-type-1`).removeClass('d-none');
                helper.visibleQuestion('.game-1-page #question-type-1');
            }
        },
        checkInsuranceAnswers(){
            if($('#question-type-2 .answers input:checked').length > 0){
                _start = true;
                var answers = [];
                $('#question-type-2 .answers .answer input:checked').each(function(){
                    answers.push($(this).val());
                });

                axios.put(_insurance_check_url, {answers: answers})
                .then(function (response) {
                    if(response.data.answers != undefined){
                        $(`#question-type-2  .answers .answer input`).attr('disabled', true);
                        for(var i in response.data.answers.good){
                            $(`#answer-${response.data.answers.good[i]}`).parent('div').addClass('good');
                        }
                        for(var i in response.data.answers.wrong){
                            $(`#answer-${response.data.answers.wrong[i]}`).parent('div').addClass('wrong');
                        }
                        for(var i in response.data.answers.ok){
                            $(`#answer-${response.data.answers.ok[i]}`).parent('div:not(.wrong)').removeClass('good').addClass('ok');
                        }
                    }

                    if(response.data.good){
                        $('#question-type-2 #why-insurance-image').attr('src', $('#question-type-2 #why-insurance-image').data('success'));
                    }else{
                        $('#question-type-2 .description > p').addClass('d-none');
                        $('#question-type-2 .description > div').removeClass('d-none');
                        $('#question-type-2 #why-insurance-image').attr('src', $('#question-type-2 #why-insurance-image').data('wrong'));
                    }
                    if(response.data.point != undefined){
                        _point = response.data.point;
                        $(`.game1 .footer .point`).html(_point);
                    }

                    $('#question-type-2 .btn-check').removeClass('d-block').addClass('d-none');
                    $('#question-type-2 .btn-next').removeClass('d-none').addClass('d-block');
                })
                .catch(function (error) {
                    swal({
                        title: error.response.data.message,
                        type: error.response.data.status,
                        timer: 4000,
                        showConfirmButton: false
                    }).then(function(){
                        _start = false;
                        window.location.href = error.response.data.url;
                    });
                });
            }else{
                helper.alert('error', 'Legalább 1 válasz megadása kötelező!');
            }
        },
        checkAttitudeAnswers(){
            if($('#question-type-3 .answers input:checked').length > 0){
                _start = true;

                axios.put(_attitude_check_url, {answer: $('#question-type-3 .answers .answer input:checked').val()})
                .then(function (response) {
                    _attitude_profile = response.data.profile;
                    $('#life-stage-end-modal .attitude-name').html(_attitude_profile.name);
                    $('#life-stage-end-modal .attitude-description').html(_attitude_profile.description);
                    _self.nextQuestion('educational');
                })
                .catch(function (error) {
                    swal({
                        title: error.response.data.message,
                        type: error.response.data.status,
                        timer: 4000,
                        showConfirmButton: false
                    }).then(function(){
                        _start = false;
                        // window.location.href = error.response.data.url;
                    });
                });
            }else{
                helper.alert('error', 'Legalább 1 válasz megadása kötelező!');
            }
        },
        checkEducationAnswers(){
            if($('#question-type-1 .answers input:checked').length > 0){
                _start = true;
                var answers = [];
                $('#question-type-1 .answers .answer input:checked').each(function(){
                    answers.push($(this).val());
                });

                $('#question-type-1 .btn-check').removeClass('d-block').addClass('d-none');

                axios.put(_education_check_url, {answers: answers})
                .then(function (response) {
                    if(response.data.answers != undefined){
                        $(`#question-type-1 .answers .answer input`).attr('disabled', true);
                        for(var i in response.data.answers.good){
                            $(`#answer-${response.data.answers.good[i]}`).parent('div').addClass('good');
                        }
                        for(var i in response.data.answers.wrong){
                            $(`#answer-${response.data.answers.wrong[i]}`).parent('div').addClass('wrong');
                        }
                        for(var i in response.data.answers.ok){
                            $(`#answer-${response.data.answers.ok[i]}`).parent('div:not(.wrong)').removeClass('good').addClass('ok');
                        }
                    }

                    if(response.data.good){
                        $('#question-type-1 #edication-image').attr('src', $('#question-type-1 #edication-image').data('success'));
                    }else{
                        $('#question-type-1 #edication-image').attr('src', $('#question-type-1 #edication-image').data('wrong'));
                    }

                    if(response.data.life_stage_point > 0){
                        $('#life-stage-end-modal .life-stage-success').removeClass('d-none');
                    }else{
                        $('#life-stage-end-modal .life-stage-failed').removeClass('d-none');
                    }
                    $('#life-stage-end-modal .life-stage-point').html(response.data.life_stage_point);

                    if(response.data.point != undefined){
                        _point = response.data.point;
                        $(`.game1 .footer .point`).html(_point);
                    }

                    _finished_url = response.data.url;
                    $('#question-type-1 .btn-next').removeClass('d-none').addClass('d-block');
                })
                .catch(function (error) {
                    swal({
                        title: error.response.data.message,
                        type: error.response.data.status,
                        timer: 4000,
                        showConfirmButton: false
                    }).then(function(){
                        _start = false;
                        window.location.href = error.response.data.url;
                    });
                });
            }else{
                helper.alert('error', 'Legalább 1 válasz megadása kötelező!');
            }
        },
        finished(){
            _start = false;
            if(_level == 3){
                $('#life-stage-end-modal').modal('show');
            }else{
                window.location.href = _finished_url;
            }
        },

        wheelOpt(){
            let w_height = $(window).height();
            let b_height = $('#wheel .btn-spin').offset().top;
            let max_height = 520;
            let min_height = 325;
            let i_height = w_height - b_height;
            
            $('#wheel .wheel').width(i_height > max_height ? max_height : (i_height < min_height ? min_height : i_height));
        },
        courtJquery(){
            $(function() {
                setTimeout(() => {
                    if($('.game1 .court .life-stage:not(.lock)').last().position().top != undefined){
                        $('html, body').animate({ scrollTop: $('.game1 .court .life-stage:not(.lock)').last().position().top - ($(window).height() / 2) }, 1000);
                    }else{
                        $('html, body').animate({ scrollTop: $('.game1 .court > div').height() }, 1000);
                    }
    
                    setTimeout(() => {
                        $('.game1 .court .opening').addClass('go');
                    }, 1500);
                    
                }, 400);

                if($('#fortuna-card-modal').length > 0){
                    setTimeout(() => {
                        $('#fortuna-card-modal').modal('show');
                    }, 1200);
                }
                if($('.game1 .court .intro').length){
                    $('.game1 .court .intro .turmix').delay(600).fadeIn(500);
                    $('.game1 .court .intro .message').delay(800).fadeIn(500);
                    $('.game1 .court').on('click', ' .intro .message .close-btn', function(){
                        $('.game1 .court .intro .turmix').delay(200).fadeOut(300);
                        $('.game1 .court .intro').delay(400).fadeOut(500);
                    });
                }

                $('.game1 .court').on('click', '.life-stage:not(.lock)', function(){
                    window.location.href = $(this).data('route');
                });
            });
        },
        jquery(){
            $('#question-type-2 .answers input').on('change', function(){
                if($('#question-type-2 .answers input:checked').length >= _insurance_max_answer){
                    $('#question-type-2 .answers input:not(:checked)').attr('disabled', true);
                }else{
                    $('#question-type-2 .answers input:not(:checked)').removeAttr('disabled');
                }
            });

            $('#wheel .btn-spin').on('click', function(){
                $(this).hide();
                $('#wheel .wheel-c').addClass('spin').css({'transform': `rotate(${400 + (Math.random() * 500)}deg)`});
                setTimeout(() => {
                    $('#wheel-modal').modal('show');
                }, 5100);
            });

            $('#wheel-modal').on('hidden.bs.modal', function (e) {
                if($(this).find('.modal-dialog').hasClass('first')){
                    game.nextQuestion('attitude');
                }
              })

              $(window).on('resize load', function(){
                    _self.wheelOpt();
              });
        },
    }
}
