export function run(){
    return {
        jquery(){
            $(function(){
                $('.new-password, .password').on('click', '.input-group svg', function(){
                    if($(this).parents('.input-group').find('input').attr('type') == 'password'){
                        $(this).parents('.input-group').find('input').attr('type', 'text');
                    }else{
                        $(this).parents('.input-group').find('input').attr('type', 'password');
                    }
                });
                
                $('.new-password').on('keyup', 'input', function(){
                    var list = $(this).parents('.new-password').find('ul');
                    if($(this).val().search(/[a-z]+/) != -1){
                        list.find('li.step-1').addClass('checked');
                    }else{
                        list.find('li.step-1').removeClass('checked');
                    }
                    if($(this).val().search(/[A-Z]+/) != -1){
                        list.find('li.step-2').addClass('checked');
                    }else{
                        list.find('li.step-2').removeClass('checked');
                    }
                    if($(this).val().search(/[0-9]+/) != -1){
                        list.find('li.step-3').addClass('checked');
                    }else{
                        list.find('li.step-3').removeClass('checked');
                    }

                    if($(this).val().length >= 8){
                        list.find('li.step-4').addClass('checked');
                    }else{
                        list.find('li.step-4').removeClass('checked');
                    }
                });

                $('.profile').on('click', '.btn-edit', function(){
                    $('#' + $(this).data('id')).addClass('show');
                    $('#' + $(this).data('id') + ' .body').animate({bottom: 0}, 200);

                });

                $('.modal-down').on('click', '.btn-cancel', function(e){
                    $('.modal-down .body').animate({bottom: '-500px'}, {
                        duration: 200,
                        complete: function(){
                            setTimeout(() => {
                                $('.modal-down').removeClass('show');
                            }, 300);
                        }
                    });
                });

                $('#registration-form').on('keydown change', 'input[name="character"], #name, #email, #password, #rule-1, #rule-2', function(){
                    let empty = false;
                    $('#name, #email, #password').each(function(){
                        if($(this).val() == ''){
                            empty = true;
                        }
                    });
                    $('#rule-1, #rule-2').each(function(){
                        if($(this).not(':checked').length > 0){
                            empty = true;
                        }
                    });
                    
                    if(!($('#character-man:checked').length > 0 || $('#character-woman:checked').length > 0)){
                        empty = true;
                    }
                    
                    if(!empty){
                        $('#registration-form .btn-primary').removeAttr('disabled');
                    }else{
                        $('#registration-form .btn-primary').attr('disabled');
                    }
                });
            });
        }
    }
}
