export function run(){
    let _self = false;
    return {
        animateBG(){
            let life_stages = [0, 3, 11, 17, 26, 40, 50, 64, 72];
                    let life_stage = $('body').data('bg');
                    life_stage = parseInt(life_stage / 10);
                    if(life_stages[life_stage] != undefined){
                        life_stage = life_stages[life_stage];
                    }else{
                        life_stage = 0;
                    }
                    
                    let window_width = $(window).width() / 1920;

                    
                    let bottom = ((window_width * 2600) - $(window).height() - ((window_width * 2600) * ((window_width * life_stage) / 100)));

                    bottom = 100 - 72;
                    console.log(bottom);
                    if(!(bottom + $(document).height() < window_width * 2600 && (-1 * bottom) < 0)){
                        bottom = (window_width * 2600) - $(document).height();
                    }
                    $('body').animate({'background-position-y': (-1 * bottom) + 'px'}, 500);
        },
        jquery(){
            // let _self = this;
            // let waiting = null;
            $('#win-game-start.message').on('click', '.close-btn', function(){
                helper.setCookie('win-game-message', 'close', 3);
                $(this).parents('.message').slideUp();
            });

            $('.message').on('click', '.close-btn', function(){
                $(this).parents('.message').fadeOut();
            });

            // $(window).on("resize load", function(){
            //     if(waiting){
            //         clearTimeout(waiting);
            //     }
            //     waiting = setTimeout(function(){
            //         _self.animateBG()
            //     }, 500);
            // })
        }
    }
}
