import { runInContext } from "lodash";

export function run() {
    return {
        unset: (array, index) => {
            array.splice(index, 1);
        },
        clone: (obj, vm) => {
            if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
                return obj;

            if (obj instanceof Date)
                var temp = new obj.constructor(); //or new Date(obj);
            else
                var temp = obj.constructor();

            for (var key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    obj['isActiveClone'] = null;
                    temp[key] = vm.clone(obj[key], vm);
                    delete obj['isActiveClone'];
                }
            }

            return temp;
        },
        nl2br: str => {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br/>' + '$2');
        },
        loader: (status) => {
            if (status) {
                $('.overlay').html('<div class="spinner-border spinner-border-reverse align-self-center text-primary" style="position:absolute;top:50%;left:50%;margin:-20px 0 0 -20px;width:40px;height:40px;"></div>').css({ opacity: 1, display: 'flex', 'align-items': 'center' });
            } else {
                $('.overlay').css('opacity', 0).delay(300).hide().html('');
            }
        },
        vmModal: (status, modal, object, form_object) => {
            if (object != undefined && form_object != undefined) {
                for (var key in form_object) {
                    form_object[key] = object[key];
                }
            }
            $(modal).modal(status);
        },
        trashObject: (object) => {
            for (var key in object) {
                if (typeof object[key] == 'object') {
                    object[key] = [];
                } else if (typeof object[key] == 'number') {
                    object[key] = 0;
                } else {
                    object[key] = '';
                }
            }

            return object;
        },

        arrayKeyExits: (key, object) => {
            if (object != undefined) {
                return key in object;
            }

            return false;
        },

        inArray: (value, array) => {
            if (array != undefined && value != undefined) {
                for (var i in array) {
                    if (array[i] == value) {
                        return true;
                    }
                }
            }

            return false;
        },

        inArrayIndex: (value, array) => {
            if (array != undefined && value != undefined) {
                for (var i in array) {
                    if (array[i] == value) {
                        return i;
                    }
                }
            }

            return false;
        },

        inArrayKey: (value, key, array) => {
            if (array != undefined && value != undefined, key != undefined) {
                for (var i in array) {
                    if (array[i][key] == value) {
                        return array[i];
                    }
                }
            }

            return false;
        },

        arrayRemove: (array, index) => {
            array.splice(index, 1);
        },

        isInt: (n) => {
            return Number(n) === n && n % 1 === 0;
        },

        getDate(date){
            if(date == undefined){
                var date = new Date();
            }
            return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + '-' + (date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate());
        },

        getDateTime(date){
            if(date == undefined){
                var date = new Date();
            }
            return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + '-' + (date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()) + ' ' + (date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()) + ':' + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes());
        },

        confirm(question, ok, cancel, url){
            swal({
                title: question,
                text: "",
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: cancel,
                confirmButtonText: ok,
                padding: '2em'
            }).then(function (result) {
                if (result.value) {
                    window.location.href = url;
                }
            });
        },

        alert(type, title){
            swal({
                title: title,
                type: type,
                timer: 2000,
                showConfirmButton: false
            });
        },

        setCookie: (cname, cvalue, exdays) => {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        
        getCookie: (cname) => {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "";
          },

        copyClipboard(id, box_id) {
            var $temp = $("<textarea>");
            $("body").append($temp);
            $temp.val($(`#${id}`).text().replace(/\s\s/gi, '').replace(/\!\s/gi, "!\n").trim()).trigger('select');
            document.execCommand("copy");
            $temp.remove();
            let color = $(`.${box_id}`).css('background');
            $(`.${box_id}`).css('background', '#90E5A8');
            setTimeout(() => {
                $(`.${box_id}`).css('background', color);
            }, 1000);
        },

        hideQuestion(page){
            $(page).removeClass('d-none');
            $(page + ' .question-text, ' + page + ' .help-text, ' + page + ' .question, ' + page + ' .question-2, ' + page + ' .answers .answer, ' + page + ' .btn-link, ' + page + ' .btn-check, ' + page + ' .question-image').hide();
        },
        visibleQuestion(page){
            var delay = 0;
            $(page + ' .question-text').delay(delay).fadeIn();
            $(page + ' .question, ' + page + ' .question-2').delay(delay += 200).fadeIn();
            $(page + ' .help-text').delay(delay += 250).fadeIn();
            var i = 1;
            $(page + ' .answers .answer').css({
                opacity: 0,
                display: 'block'
            });
            $(page + ' .answers .answer').delay(delay).each(function(){
                $(this).delay(200  * (i++)).animate({opacity: 1}, 400);
            });

            delay += 200  * (i++);

            $(page + ' .question-image').delay(delay += 200).fadeIn(800);
            $(page + ' .btn-link').delay(delay += 200).fadeIn();
            $(page + ' .btn-check').delay(delay += 500).fadeIn(400);
            return delay;
        },
    };
}
